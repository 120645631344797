<template>
  <div></div>
</template>

<script>
export default {
  beforeCreate() {
    let liffId = "";
    if (window.location.hostname !== "dev-line-eservice.skl.co.th") {
      liffId = "1654133345-xG7kaNvB";
    } else liffId = this.$liff_ID_Buzzebees;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.openBuzzebees(this.lineProfile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.openBuzzebees(this.lineProfile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {

  },

  data() {
    return {};
  },
  methods: {
    async openBuzzebees(id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      this.$isLoadingAlpha = true;
      await fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginbuzzebees/${id}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            window.open(result.detail.url, "_self");
          }
        })
        .catch(error => console.log("error", error));
      this.$isLoadingAlpha = false;
    }
  }
};
</script>

<style></style>
